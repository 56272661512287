import React       from 'react';

function convertTimestamp(timestamp) {
    var d = new Date(timestamp * 1000), // Convert the passed timestamp to milliseconds
        yyyy = d.getFullYear(),
        mm = ('0' + (d.getMonth() + 1)).slice(-2),  // Months are zero based. Add leading 0.
        dd = ('0' + d.getDate()).slice(-2),         // Add leading 0.
        hh = d.getHours(),
        h = hh,
        min = ('0' + d.getMinutes()).slice(-2),     // Add leading 0.
        ampm = 'AM',
        time;

    if (hh > 12) {
        h = hh - 12;
        ampm = 'PM';
    } else if (hh === 12) {
        h = 12;
        ampm = 'PM';
    } else if (hh == 0) {
        h = 12;
    }

    // ie: 2014-03-24, 3:00 PM
    time = mm + '/' + dd;
    return time;
}

class Table extends React.Component {

    state = {
     
    };

    filterToCats = (arg) => {

        let result = {
            George: 0,
            Angus: 0,
            Minnie: 0,
        };

        for(let i = 0; i < arg.length; i += 1){

            let amount = 0;

            // Convert the string to the number amount.
            if(arg[i].amount === "1/4 Scoop"){
                amount = 0.25
            }

            if(arg[i].amount === "1/2 Scoop"){
                amount = 0.5
            }

            if(arg[i].amount === "3/4 Scoop"){
                amount = 0.75
            }

            if(arg[i].amount === "1 Scoop"){
                amount = 1
            }

            // Add to each cat.
            for(let g = 0; g < arg[i].cat.length; g += 1){
                result[arg[i].cat[g]] += amount
            }
        }

        return result;
    }


    render(){

        const day = 86400;
        const currentTime = Math.floor(Date.now() / 1000);

        let day1Data = [];
        let day2Data = [];
        let day3Data = [];
        let day4Data = [];
        let day5Data = [];
        let day6Data = [];
        let day7Data = [];

        // Filter the records to ONLY be the ones in the last 7 days.
        for(let i = 0; i < this.props.data.length; i += 1){

            // Day 1
            if(this.props.data[i].ts > (currentTime - (day * 1))){
                day1Data.push(this.props.data[i]);
                continue;
            }

            // Day 2
            if(this.props.data[i].ts > (currentTime - (day * 2))){
                day2Data.push(this.props.data[i]);
                continue;
            }

            // Day 3
            if(this.props.data[i].ts > (currentTime - (day * 3))){
                day3Data.push(this.props.data[i]);
                continue
            }

            // Day 4
            if(this.props.data[i].ts > (currentTime - (day * 4))){
                day4Data.push(this.props.data[i]);
                continue
            }

            // Day 5
            if(this.props.data[i].ts > (currentTime - (day * 5))){
                day5Data.push(this.props.data[i]);
                continue
            }

            // Day 6
            if(this.props.data[i].ts > (currentTime - (day * 6))){
                day6Data.push(this.props.data[i]);
                continue
            }

            // Day 7
            if(this.props.data[i].ts > (currentTime - (day * 7))){
                day7Data.push(this.props.data[i]);
                continue
            }
        }

        // Sum up the totals for each day.
        let day1Total = this.filterToCats(day1Data);
        let day2Total = this.filterToCats(day2Data);
        let day3Total = this.filterToCats(day3Data);
        let day4Total = this.filterToCats(day4Data);
        let day5Total = this.filterToCats(day5Data);
        let day6Total = this.filterToCats(day6Data);
        let day7Total = this.filterToCats(day7Data);

        console.log("day1Total", day1Total)

        return (
            <div className="table-container">
                <table>
                    <tr>
                        <th>Cat</th>
                        <th>{convertTimestamp(currentTime - (day * 0))}</th>
                        <th>{convertTimestamp(currentTime - (day * 1))}</th>
                        <th>{convertTimestamp(currentTime - (day * 2))}</th>
                        <th>{convertTimestamp(currentTime - (day * 3))}</th>
                        <th>{convertTimestamp(currentTime - (day * 4))}</th>
                        <th>{convertTimestamp(currentTime - (day * 5))}</th>
                        <th>{convertTimestamp(currentTime - (day * 6))}</th>
                        <th>Total</th>
                    </tr>
                    <tr>
                        <td>George</td>
                        <td>{day1Total["George"]} Scoops</td>
                        <td>{day2Total["George"]} Scoops</td>
                        <td>{day3Total["George"]} Scoops</td>
                        <td>{day4Total["George"]} Scoops</td>
                        <td>{day5Total["George"]} Scoops</td>
                        <td>{day6Total["George"]} Scoops</td>
                        <td>{day7Total["George"]} Scoops</td>
                        <td>{day1Total["George"] += day2Total["George"] += day3Total["George"] += day4Total["George"] += day5Total["George"] += day6Total["George"] +=day7Total["George"]}</td>
                    </tr>
                    <tr>
                        <td>Angus</td>
                        <td>{day1Total["Angus"]} Scoops</td>
                        <td>{day2Total["Angus"]} Scoops</td>
                        <td>{day3Total["Angus"]} Scoops</td>
                        <td>{day4Total["Angus"]} Scoops</td>
                        <td>{day5Total["Angus"]} Scoops</td>
                        <td>{day6Total["Angus"]} Scoops</td>
                        <td>{day7Total["Angus"]} Scoops</td>
                        <td>{day1Total["Angus"] += day2Total["Angus"] += day3Total["Angus"] += day4Total["Angus"] += day5Total["Angus"] += day6Total["Angus"] += day7Total["Angus"]}</td>
                    </tr>
                    <tr>
                        <td>Minnie</td>
                        <td>{day1Total["Minnie"]} Scoops</td>
                        <td>{day2Total["Minnie"]} Scoops</td>
                        <td>{day3Total["Minnie"]} Scoops</td>
                        <td>{day4Total["Minnie"]} Scoops</td>
                        <td>{day5Total["Minnie"]} Scoops</td>
                        <td>{day6Total["Minnie"]} Scoops</td>
                        <td>{day7Total["Minnie"]} Scoops</td>
                        <td>{day1Total["Minnie"] += day2Total["Minnie"] += day3Total["Minnie"] += day4Total["Minnie"] += day5Total["Minnie"] += day6Total["Minnie"] += day7Total["Minnie"]}</td>
                    </tr>
                </table>
            </div>
        )
    };
}


export default Table;

import React from 'react';

class Footer extends React.Component {

  state = {
  };

  componentDidMount(){}

  render(  ){
    return (
    <div className = "Footer-Container">
     <div className= "Footer1">
      <div className="list">
        <div className="top">Cat Footer</div>
        <div className="bottom">Cat Beans</div>
      </div>
      <div className="list">
        <div className="top">Link to Nowhere</div>
        <div className="bottom">Oooo nowhere</div>
      </div>
      <div className="list">
        <div className="top">Angus Livefeed</div>
        <div className="bottom">Ha Ha JK</div>
      </div>
      <div className="list">
        <div className="top">Origins of Nom</div>
        <div className="bottom">NOOOOOOM</div>
      </div>
    
      </div>
    </div>
    )
  };
}

export default Footer;

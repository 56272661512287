import React       from 'react';

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

const minutes = 60
const hours = minutes * 60

const normalTime = 2 * hours
const madTime = 4 * hours

/*
    To figure out:
    - How to find how much time has past
        (done) 1. Get the current time (in unix timestamp).
        (done) 2. Subtract the lastFed from current time.
        (done) 3. Convert it to days:hours:minutes:seconds
    - Angus status (three pictures) that change based on how long its been.
        (done) 1. Happy
        (done) 2. Normal
        (done) 3. Mad
    - (done) Make cron to automatically update every second.
    
    - Honk honk, honk, honk honk honk honk honk honk honk honk honk honk honk honk honk honk
            _
           {_}
           /*\
          /_*_\
         {('o')}
      C{{([^*^])}}D
          [ * ]
         /  Y  \
        _\__|__/_
       (___/ \___)
*/

class Timer extends React.Component {

  state = {
      secondsSinceFed: "",
      timerHandle: null,
      difference: 0,
  };

    
  calculate = () => {
    const unixTime = Math.floor(Date.now() / 1000);
    let diff = unixTime - this.props.lastFed 
    let output = secondsToDhms(diff);

    // Update internal state.
    this.setState({
        secondsSinceFed: output,
        difference: diff,
    });
  }

  componentDidMount(){
    this.calculate();

    let a = setInterval(this.calculate, 1000);
    this.setState({ timerHandle: a })
  }

  componentWillUnmount(){
    clearInterval(this.state.timerHandle);
  }


  render(){

    let angusImage = <img src={this.props.catHappy} alt="Angus approves"/>

    let color="timehappy"

    if (this.state.difference >= normalTime) {
        angusImage = <img src={this.props.catNormal} alt="Angus Is Okay" />
        color = "timenormal";
    }

    if (this.state.difference >= madTime) {
        angusImage = <img src={this.props.catMad} alt="Angus Is NOT Okay. FEED NOW" />
        color = "madTime"
    }
/*
    //george timer
    let georgeImage = <img src={GeorgeHappy} alt="George approves"/>

    
    if (this.state.difference >= normalTimeGeorge) {
        georgeImage = <img src={GeorgeNormal} alt="George Is Okay" />
        color = "timenormal";
    }

    if (this.state.difference >= madTimeGeorge) {
        georgeImage = <img src={GeorgeMad} alt="George Is NOT Okay. FEED NOW" />
        color = "madTime"
    }
  //minnie timer
    let minnieImage = <img src={MinnieHappy} alt="Minnie approves"/>

    
    if (this.state.difference >= normalTime) {
        minnieImage = <img src={MinnieNormal} alt="Minnie Is Okay" />
        color = "timenormal";
    }

    if (this.state.difference >= madTime) {
        minnieImage = <img src={MinnieMad} alt="Minnie Is NOT Okay. FEED NOW" />
        color = "madTime"
    }
*/

    return (
        <div className="timer-container">
            <div className="picture">
                {angusImage}
                
            </div>
            <div className="text">
                {this.props.catName} was last fed
            </div>
            <div className={`timeClock ${color}`}>
             {this.state.secondsSinceFed} 
             </div>
             <div className="text">ago</div>
        </div>)
    };
}

export default Timer;

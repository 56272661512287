import React from 'react';

import './styles/App.css';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"; 

// Angus
import AngusHappy from './photos/AngusHappy.jpg';
import AngusNormal from './photos/AngusNormal.jpg';
import AngusMad from './photos/AngusMad.jpg';

// George
import GeorgeHappy from './photos/GeorgeHappy.jpg';
import GeorgeNormal from './photos/GeorgeNormal.jpg';
import GeorgeMad from './photos/GeorgeMad.jpg';

// Minnie
import MinnieHappy from './photos/MinnieHappy.jpg';
import MinnieNormal from './photos/MinnieNormal.jpg';
import MinnieMad from './photos/MinnieMad.jpg';

import Timer from './component/Timer';
import Table from './component/Table';

// Containers
import Homepage from './containers/Homepage';

// Components
import Navbar from './component/Navbar';
import Footer from './component/Footer';

var requestPromise = require('request-promise');

export default class App extends React.Component {

  state = {
    lastTimeFedMinnie: 1644871269,
    lastTimeFedGeorge: 1644871269,
    lastTimeFedAngus:  1644871269,

    data: []
  }
    
  loadData = () => {
    var options = {
       uri: 'https://api.whofedthecat.fish',
       json: true
    };

    let thisthis = this;

    // Loading the list.
    requestPromise(options)
      .then(function (response) {

        let defaultTimestamp = 1644871269

        let minnie = defaultTimestamp;
        let george = defaultTimestamp;
        let angus  = defaultTimestamp;

        let itemInArray = response[response.length - 1];

        for(let i = (response.length - 1); i > 0; i -= 1){
          let current = response[i];

          for(let cat = 0; cat < current["cat"].length; cat += 1){
            let currentCat = current["cat"][cat];

            if (currentCat == "George" && george == defaultTimestamp){
              george = current["ts"]
            } 
            if (currentCat == "Angus" && angus == defaultTimestamp){
              angus = current["ts"]
            }
            if (currentCat == "Minnie" && minnie == defaultTimestamp){
              minnie = current["ts"]
            }
          }

          // If we have them all set, break out of the loop.
          if(
            minnie !== defaultTimestamp &&
            george !== defaultTimestamp &&
            angus !== defaultTimestamp
          ){
            break;
          }
        }

        thisthis.setState({
          data: response,
          lastTimeFed: itemInArray["ts"],

          lastTimeFedMinnie: minnie,
          lastTimeFedGeorge: george,
          lastTimeFedAngus:  angus,
        })    
      })
      .catch(function (err) {
           // API call failed...
           console.log("err", err)
           alert("Error Loading")
       });
  }

  componentDidMount(){
    this.loadData();
  }

  render(){
    return (
      <Router>
        <div className="c-App">
            <div className="App">
              <div className="c-nav-bar">
                <Navbar />
              </div>
              <div className="content">
                <Switch>
                  <Route path="/" exact>
                    <div className="timer-container">
                    <Timer 
                      catName="George"
                      catMad={GeorgeMad}
                      catHappy={GeorgeHappy}
                      catNormal={GeorgeNormal}
                      lastFed={this.state.lastTimeFedGeorge}
                    />
                    <Timer 
                      catName="Angus"
                      catMad={AngusMad}
                      catHappy={AngusHappy}
                      catNormal ={AngusNormal}
                      lastFed={this.state.lastTimeFedAngus}       
                    />
                    <Timer 
                      catName="Minnie"
                      catMad={MinnieMad}
                      catHappy={MinnieHappy}
                      catNormal ={MinnieNormal} 
                      lastFed={this.state.lastTimeFedMinnie}
                    />
                    </div>
                    <Homepage loadData={this.loadData} />
                    <Table data={this.state.data} />
                  </Route>
                  <Route path='*'>
                    <div>
                      Error: unable to find page.
                    </div>
                  </Route>
                </Switch>
              </div>
              <div className="c-footer">
                <Footer />
              </div>
            </div>
          </div>
        </Router>
      );
   }
}


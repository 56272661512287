import React from 'react';
import Confetti from 'react-confetti';

var requestPromise = require('request-promise');

let catList = ["George", "Angus",  "Minnie", "All Cats"]

class Homepage extends React.Component {
  
  state = {
    // Cats selected.
    George: false,
    Angus: false,
    Minnie: false,
    "All Cats": false,

    // Amount Fed.
    amount: "",

    rain: false,
  };

  resetEverything = () => {
    this.setState({
      George: false,
      Angus: false,
      Minnie: false,
      "All Cats": false,
      amount: "",
    })

    this.props.loadData();
  }
    
  handleSubmit = () => {
    
    if (this.state.Angus === false && this.state.George === false && this.state.Minnie === false) {
      alert("Please select a cat")
      return;
     }
    
    if (this.state.amount === "") {
      alert("Please select an amount")
      return;
     }

     if (this.state.amount === "Confetti") {
      this.setState({ rain: true });
      setTimeout(()=>{ this.setState({ rain: false })}, 4500);
      this.resetEverything ()
      return;
     }
    
      let catsThatWereFed = []

      if (this.state.George === true){
        catsThatWereFed.push("George");
      }
      if (this.state.Angus === true) {
        catsThatWereFed.push("Angus")
      }
      if (this.state.Minnie === true) {
        catsThatWereFed.push("Minnie")
      }

      var options = {
        method: 'POST',
        uri: 'https://api.whofedthecat.fish',
        body: {
          "ts": Math.floor(Date.now() / 1000),
          "amount": this.state.amount,
          "cat": catsThatWereFed
        },
        json: true
      };
      /*
      for(let i = 0; i < this.props.data.length; i += 1){

            // Day 1
            if(this.props.data[i].ts > (currentTime - (day * 1))){
                day1Data.push(this.props.data[i]);
                continue;
     */
    
                let catNames = "";

    for(let u= 0; u<catsThatWereFed.length; u +=1){
      console.log(u) 
      if(catNames !== ""){
        catNames += ", "
      } 
      catNames += catsThatWereFed[u]
    }
    

      let localCopyOfthis = this;
    
      requestPromise(options)
      .then(function (parsedBody) {
        localCopyOfthis.setState({ rain: true });
        setTimeout(()=>{ localCopyOfthis.setState({ rain: false })}, 4500);
        localCopyOfthis.resetEverything ()
      })
      .catch(function (err) {
        console.log("err", err);
        alert("You failed to feed the cats. Try again.")
      });

      // Discord webhook.
      var optionsDiscord = {
        method: 'POST',
        uri: 'https://discord.com/api/webhooks/949566819239362570/dMStL8PGzO_3nTiW5_Hjnc-18F-cTapFbzjTY6Nq30RhbXKi4Tp98mjOjth2XxfQjALT',
        body: {
          "content":  "**" + catNames + "** " + ((catsThatWereFed.length>1) ? "were" : "was") + " fed **" + this.state.amount + "**"
        },
        json: true
      };

      requestPromise(optionsDiscord)
      .then(function (parsedBody) {

      })
      .catch(function (err) {
        alert("unable to post")
      });



  }

  toggleCatInList = (arg) => {
    if (arg === "All Cats") {
      if (this.state["All Cats"] === false){
          this.setState({
            George: true,
            Angus: true,
            Minnie: true,
            "All Cats": true,
          })
      }else{
           this.setState({
            George: false,
            Angus: false,
            Minnie: false,
            "All Cats": false,
          })
      }
    } else {
      this.setState({ [arg]: !this.state[arg] });
    }
  }

  changeAmount = (arg) => {
    this.setState({ amount: arg.target.value });
  }

  render(){

    let optionList = [];    
  
    // Generate the list of cat options.
    for(let i = 0; i < catList.length; i += 1){

      let currentCat = catList[i];

      optionList.push((
        <div>
          <input
            type="checkbox"
            checked={this.state[catList[i]]}
           onChange={()=>{this.toggleCatInList(currentCat)}}
          />
          {currentCat}
        </div>)
      )
    }

    return (
      <div className="homepage-container">
           <form> 
        <div className= "checkboxes">
        {optionList}
        </div>
        <div className= 'DropDownParent'>
          <div>
            <label>How Much Did They Get</label>
          </div>
          <div className= "DropDownStyle"></div>
          <select
            value={this.state.amount}
            onChange={this.changeAmount}
          >
            <option value=""> </option>
            <option value="1/4 Scoop"> 1/4 Scoop </option>
            <option value="1/2 Scoop"> 1/2 Scoop </option>
            <option value="3/4 Scoop"> 3/4 Scoop </option>
            <option value="1 Scoop"> 1 Scoop </option>
            <option value="Confetti"> Nothing I just like confetti</option>
          </select>
        </div>
        <div>
        <div className="ButtonGlam" onClick={this.handleSubmit} >
           FEED the CATS
        </div>
        </div>
          </form>
          <div style={{ display: (this.state.rain) ? "block" : "none"}}>
            <Confetti
            recycle={true}
              width={1000}
              height={1000}
              run={this.state.rain}
              numberOfPieces={200}
              />
          </div>
        
      </div>
      )
  };
}

export default Homepage;